
import { get, patch, post } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IExaAnswerItem, IExaQAItem, IExaTask } from "../../state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import Security from "@/components/Security.vue";
import DragAndDrop from "@/components/DragAndDrop.vue";

@Component({
    components: {
        EntityPropertiesEdit,
        Security,
        DragAndDrop
    }
})
export default class ExaTaskView extends Vue {
    @Prop({ required: false, default: "" })
    public taskID!: string;

    public get taskIDParams(): string {
        return this.$route.params.id;
    }

    @Prop({ required: false, default: "" })
    public skuID!: string;

    public get skuIDParams(): string {
        return this.$route.params.sid;
    }

    get isReadonly(): boolean {
        if (!this.entity) return false;
        return this.entity.status !== "created";
    }

    getStatusColor() {
        if (!this.entity) return "";
        const status = this.entity.status;
        if (status == "created")
            return "orange";
        if (status == "published")
            return "green";
        if (status == "archived")
            return "red";
        return "";
    }


    public allQuestion: IExaQAItem[] = [];
    public selectedQuestion: IExaQAItem[] = [];


    qDialog = {
        isActive: false,


        item: {
            question: "",
            answers: [] as IExaAnswerItem[],
            desc: "",
            allowedMultipleAnswer: false
        } as IExaQAItem
    }

    importDialog = {
        isActive: false,
        selectedId: null as (IExaQAItem | null)
    }

    importSelected() {
        this.selectedQuestion.push(this.importDialog.selectedId!);
        this.importDialog.isActive = false;
        this.importDialog.selectedId = null;
    }


    publishThis() {
        let tid = this.taskIDParams ?? this.taskID;
        let sid = this.skuIDParams ?? this.skuID;
        post(`/api/@examination/exa/sku/${sid}/task/${tid}/publish`, {}).then(x => {
            console.log("Success published this task");
            this.entity!.status = "published";
        })
    }

    @Watch("selectedQuestion")
    watched_selectedQuestion() {
        if (!this.isReady) return;
        let tid = this.taskIDParams ?? this.taskID;
        let sid = this.skuIDParams ?? this.skuID;

        patch(`/api/@examination/exa/sku/${sid}/task/${tid}/question`, this.selectedQuestion)
            .then(x => console.log("Success set qa into task", x.data));
    }

    fields: Array<any> = [
        {
            "icon": "mdi-file-document",
            "caption": "Наименование задачи",
            "placeholder": "Наименование не указано",
            "name": "name",
            "type": "string",
            "hint": "hint",
            "message": "Введите наименование задачи"
        }
    ];





    headers: Array<any> = [
        {
            text: 'Вопрос',
            align: 'start',
            sortable: true,
            value: 'question',
        }
    ];

    @Watch("selectedQuestion")
    selectedWatch() {
        console.log("selectedQuestion", this.selectedQuestion);
    }


    isReady: boolean = false;


    public entity: IExaTask | null = null;

    mounted() {
        let tid = this.taskIDParams ?? this.taskID;
        let sid = this.skuIDParams ?? this.skuID;
        get(`/api/@examination/exa/sku/${sid}/task/${tid}`).then((x) => {
            this.entity = x.data;

            get(`/api/@examination/exa/sku/${sid}/task/${tid}/question`).then(y => {
                this.selectedQuestion = y.data;
                console.warn("FETCH ALL SELECTED QA", this.selectedQuestion);

                get(`/api/@examination/exa/question`)
                .then(w => {
                    this.allQuestion = w.data;
                    console.log(this.allQuestion);
                    this.isReady = true;
                });
            });
            
            

            
        });
    }

    onQuestionMove() {
    }
    onQuestionRemove(task: any) {
        console.log("onQuestionRemove", task);
    }
    onQuestionAdd() {
        this.qDialog.isActive = true;
    }

    updateTask(task: IExaTask) {
        console.log("updateTask", task);
    }

    onTextFormat(qa: IExaQAItem) {
        return qa.question;
    }

    createQuestion() {
        let tid = this.taskIDParams ?? this.taskID;
        let sid = this.skuIDParams ?? this.skuID;

        post(`/api/@examination/exa/sku/${sid}/task/${tid}/question`, this.qDialog.item)
            .then(x => {
                console.log("Success add new question", x.data);
                this.qDialog.isActive = false;
                this.qDialog.item.question = "";
                this.qDialog.item.answers = [];
                this.selectedQuestion.push(x.data);
            });
    }

    addNewAnswer() {
        this.qDialog.item.answers.push({
            polarity: false,
            text: "sample answer",
            weight: 1
        });
    }
    togglePolarity(item: IExaAnswerItem) {
        item.polarity = !item.polarity;
    }
}
